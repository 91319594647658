// ================================================================================
// kv.js
// TOP ページのキービジュアルのスクリプトファイル
// ================================================================================

(function(){
  'use strict';

  const kvAnm = document.getElementById('rr-kv');

	if ( kvAnm ) {

  // タイミング設定：下層は3秒、上層は4秒、合計7秒で次のスライドへ
  const lowerLayerTime = 3000;  // 下層パス（rr-kv-image1）のアニメーション時間
  const upperLayerTime = 4000;  // 上層（rr-kv-image2）のフェードイン時間
  const totalTime = lowerLayerTime + upperLayerTime; // 7000ms

  let currentSlide = 1;
  let timer = null;
  // すべてのsetTimeoutのIDを格納する配列
  let animationTimeouts = [];

  // 既存のタイムアウトをすべてクリアする関数
  function clearAnimationTimeouts() {
    animationTimeouts.forEach(t => clearTimeout(t));
    animationTimeouts = [];
  }

  // ▼ 下層パス
  const shapePaths = document.querySelectorAll('.rr-kv-image1 svg path');
  // ▼ 上層
  const image2Container = document.querySelector('.rr-kv-image2');
  const clipImage  = document.getElementById('clipImage');
  const clipAnim   = document.getElementById('clipAnim');

  // ▼ テキスト/ナビ
  const slideItems = document.querySelectorAll('.rr-kv-item');
  const navItems   = document.querySelectorAll('.rr-kv-nav-item');

  // ────── 画像プリロード ──────
  function preloadImages(slides, callback) {
    let loaded = 0;
    slides.forEach(slide => {
      const img = new Image();
      img.onload = img.onerror = function(){
        loaded++;
        if(loaded === slides.length){
          callback();
        }
      };
      img.src = slide.href;
    });
  }

  const slides = [
    { href:"/img/top/kv_1.jpg", toX:-40 },
    { href:"/img/top/kv_2.jpg", toX:-40 },
    { href:"/img/top/kv_3.jpg", toX:-40 },
    { href:"/img/top/kv_4.jpg", toX:-40 }
  ];

  // 画像読み込み完了後に開始
  preloadImages(slides, function(){
    showSlide(1);
    startAutoPlay();
  });

  // ────── ナビクリック ──────
  navItems.forEach(li=>{
    const link = li.querySelector('a');
    const sNum = parseInt(link.getAttribute('data-slide'),10);
    link.addEventListener('click',(e)=>{
      e.preventDefault();
      if(sNum === currentSlide) return;
      stopAutoPlay();
      showSlide(sNum);
      currentSlide = sNum;
      startAutoPlay();
    });
  });

  function showSlide(num){
  // 新たなスライド開始前に前のアニメーションのタイムアウトをすべてクリア
  clearAnimationTimeouts();

  // 1) 前スライドの上層（rr-kv-image2）のフェードアウト
  image2Container.classList.remove('is-visible');

  // 2) テキスト／ナビの切替
  // 一旦すべてのスライドから rr-kv-item--active クラスを除去
  slideItems.forEach(it => it.classList.remove('rr-kv-item--active'));
  navItems.forEach(n => n.classList.remove('rr-kv-nav-item--active'));
  navItems[num-1].classList.add('rr-kv-nav-item--active');

  // ※ ここではすぐに rr-kv-item--active を追加せず、少し遅れて追加することで、初回もアニメーションが発生するようにする
  const tContent = setTimeout(() => {
    slideItems[num-1].classList.add('rr-kv-item--active');
  }, 50); // 50ms のディレイでクラスを追加
  animationTimeouts.push(tContent);

  // 3) 下層パス（白パス）のランダム表示開始（全体でlowerLayerTime=3000ms）
  toggleWhitePaths(num);

  // 下層パスのアニメーション完了後、上層のフェードインを開始する
  const t1 = setTimeout(()=>{
    // 4-B) SMILの再始動
    clipImage.classList.add('is-hidden');

    // 4-A) クリップパスの差し替え
    const clipPaths = document.querySelectorAll('.clip-path-seg');
    clipPaths.forEach(p => p.style.display = 'none');
    const matched2 = [...clipPaths].filter(p => p.classList.contains('rr-kv-img-' + num));
    matched2.forEach(p => p.style.display = 'inline');


	// 先に属性更新とアニメーション開始
	clipImage.setAttribute('href', slides[num-1].href);
	clipAnim.setAttribute('to', slides[num-1].toX);
	clipAnim.beginElement();

	// 強制再描画を行ってから、少し遅延して解除
	clipImage.offsetHeight;  // 再描画を強制
	setTimeout(() => {
		clipImage.classList.remove('is-hidden');
	}, 100);

    // 4-C) 上層のフェードイン
    image2Container.classList.add('is-visible');

    // オプション：一定時間後に下層パスのvisibleクラスをリセット
    const t3 = setTimeout(()=>{
      shapePaths.forEach(p => {
        p.classList.remove('path--visible');
        p.classList.add('path--hidden');
      });
    }, 750);
    animationTimeouts.push(t3);
  }, lowerLayerTime);
  animationTimeouts.push(t1);
}

  function toggleWhitePaths(num) {
    // すべてのパスを初期状態（非表示）にする
    shapePaths.forEach(p => {
      p.classList.remove("path--visible");
      p.classList.add("path--hidden");
    });

    // 対象のパスのみ抽出（例：rr-kv-img-1 など）
    let matched = [...shapePaths].filter(p =>
      p.classList.contains("rr-kv-img-" + num)
    );

    // ランダムな順番に並べ替え
    matched.sort(() => Math.random() - 0.5);

    // 各パスを、全体でlowerLayerTime（3000ms）になるように均等な間隔で表示する
    const delayStep = lowerLayerTime / matched.length;
    const fadeTime = 300;  // 各パスのフェードイン時間（300ms, cubic-bezierでスムーズに）

    matched.forEach((p, i) => {
      const t = setTimeout(() => {
        // p.style.transition = `opacity ${fadeTime}ms cubic-bezier(0.4, 0, 0.2, 1)`;
        p.classList.remove("path--hidden");
        p.classList.add("path--visible");
      }, i * delayStep);
      animationTimeouts.push(t);
    });
  }

  function startAutoPlay(){
    timer = setInterval(()=>{
      const next = (currentSlide % slides.length) + 1;
      showSlide(next);
      currentSlide = next;
    }, totalTime);
  }
  function stopAutoPlay(){
    if(timer){
      clearInterval(timer);
      timer = null;
    }
  }

}

})();

