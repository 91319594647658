// ================================================================================
// script.js
// 案件用のスクリプトファイル
// ================================================================================

// DOM Read for Vanilla
// --------------------------------------------------------------- //

document.addEventListener('DOMContentLoaded', () => {

	// breakpoint
	const breakpoint = window.matchMedia( 'screen and (min-width: 960px)' )

	// scroll volume
	function getScrolled() {
		return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
	}



	// variable
	// --------------------------------------------------------------- //

	// scroll
	let scrollTop = window.pageYOffset

	// url
	let url = location.href



	// Dropdown for mobile
	// Note: for mobile
	// --------------------------------------------------------------- //

	const dropDownButton = document.getElementById('rr-mobile-menu');
	const menuOpenTarget = document.querySelector('body');
	//const dropDownMenu   = document.getElementById('js-site-menu');

	if ( dropDownButton ) {
		dropDownButton.addEventListener('click', function() {
			dropDownButton.classList.toggle('rr-site-menu-button--close');
			menuOpenTarget.classList.toggle('rr-menu-open');
			//dropDownMenu.classList.toggle('is-active');
		} )
	}



	// Header Sub Menu
	// Note: for mobile
	// --------------------------------------------------------------- //

	const menuIconButtons = document.querySelectorAll('.rr-header-menu-icon-button');

	menuIconButtons.forEach(button => {
		button.addEventListener('click', function(e) {
			e.preventDefault();
			const subMenuList = this.nextElementSibling;

			if (subMenuList) {
				subMenuList.classList.toggle('rr-header-sub-menu-list--open');
				this.classList.toggle('rr-header-menu-icon-button--close');
			}
		});
	});



	// Scrolling Header Background
	// Note: for pc
	// --------------------------------------------------------------- //

	const header = document.getElementById('rr-header');

	function headerUpdateAnimationClass() {
		if (window.scrollY > 100) {
			header.classList.add('rr-site-header--scrolling');
		} else {
			header.classList.remove('rr-site-header--scrolling');
		}
	}

	if ( header ) {
		// ページ読み込み時にもチェック
		headerUpdateAnimationClass();

		// スクロール時にチェックする
		window.addEventListener('scroll', headerUpdateAnimationClass);
	}




	// Scrolling Perlin Animation
	// --------------------------------------------------------------- //

	const perlinTargetWrapper = document.getElementById('rr-perlin-animation-wrapper');

	// スクロール量に応じてクラスを付与/削除する関数
	function updateAnimationClass() {
		if (window.scrollY > 200) {
			perlinTargetWrapper.classList.add('rr-perlin-animation-wrapper--scroll');
		} else {
			perlinTargetWrapper.classList.remove('rr-perlin-animation-wrapper--scroll');
		}
	}

	if ( perlinTargetWrapper ) {
		// ページ読み込み時にもチェック
		updateAnimationClass();

		// スクロール時にチェックする
		window.addEventListener('scroll', updateAnimationClass);
	}



	// Fixed button
	// Note: for mobile, top only
	// --------------------------------------------------------------- //


	// 固定ボタンとフッターの要素を取得
	const fixedButton = document.getElementById('rr-fixed-button');
	const footer = document.querySelector('.rr-site-footer');

	// 要素が存在しない場合は処理を中断
	if (!fixedButton || !footer) {
		return;
	}

	// IntersectionObserver のオプション設定
	const observerOptions = {
		root: null,       // ビューポートをルートとする
		threshold: 0      // 要素が1ピクセルでも見えたらコールバック実行
	};

	// コールバック関数：フッターが交差しているかチェック
	const observerCallback = (entries) => {
		entries.forEach(entry => {
		if (entry.isIntersecting) {
			// フッターが表示されたらクラスを付与
			fixedButton.classList.add('rr-fixed-button--hidden');
		} else {
			// フッターが見えなくなったらクラスを削除
			fixedButton.classList.remove('rr-fixed-button--hidden');
		}
		});
	};

	// IntersectionObserver のインスタンスを作成
	const observer = new IntersectionObserver(observerCallback, observerOptions);

	// フッター要素の監視を開始
	observer.observe(footer);




} );