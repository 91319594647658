// ================================================================================
// perlin-animation.js
// パーリンアニメーションの制御用
// ================================================================================

import perlin from './perlin';

// メインキャンバスの取得（alphaをfalseにすることでパフォーマンス向上を期待）
const canvas = document.getElementById('rr-perlin-animation');

if ( canvas ) {
	const context = canvas.getContext("2d", { alpha: true });

	// オフスクリーンキャンバスを作成
	const offscreenCanvas = document.createElement('canvas');
	const offscreenCtx = offscreenCanvas.getContext('2d');

	let actW = 0;
	let stageW = 0;
	let stageH = 0;
	let lineNum = 60;
	let amplitude = 0;
	let lineSpacing = 0;
	let waveCenter = 0;

	// 初期設定とリサイズイベント
	resize();

	// requestIdleCallback が使える場合は、ブラウザがアイドル状態になったときに tick() を開始する
	if (window.requestIdleCallback) {
		requestIdleCallback(() => {
			tick();
		});
	} else {
		// 対応していない場合はすぐに tick() を呼ぶ
		tick();
	}
	window.addEventListener("resize", resize);

	function resize() {
		actW = window.innerWidth || document.documentElement.clientWidth;
		// stageW = window.innerWidth || document.documentElement.clientWidth;
		// stageH = window.innerHeight || document.documentElement.clientHeight;
		stageW = innerWidth * devicePixelRatio;
		stageH = innerHeight * devicePixelRatio;
		canvas.width = stageW;
		canvas.height = stageH;
		offscreenCanvas.width = stageW;
		offscreenCanvas.height = stageH;

		// 画面サイズに応じた設定
		if (actW <= 781) {
			lineNum = 30;
			amplitude = stageH / 2;
			lineSpacing = 2;
			waveCenter = stageH / 2;
		} else {
			lineNum = 40;
			amplitude = stageH / 2;
			lineSpacing = 2;
			waveCenter = stageH / 2.5;
		}

	}

	function tick() {
		requestAnimationFrame(tick);
		const time = Date.now() / 5000;
		draw(time);
	}

	function draw(time) {
		// オフスクリーンキャンバスをクリア
		offscreenCtx.clearRect(0, 0, stageW, stageH);
		offscreenCtx.lineWidth = 1.5;

		//const colors = ["#9C97A5", "#8B6790", "#FFE08A"];
		const colors = ["#83808F", "#6A406D", "#FFD149"];
		const segmentNum = 150;

		[...Array(lineNum).keys()].forEach((j) => {
			offscreenCtx.beginPath();
			[...Array(segmentNum).keys()].forEach((i) => {
				const x = (i / (segmentNum - 1)) * stageW;
				const px = i / 80;
				const py = j * lineSpacing / 100 + time / 1.5;
				const y = amplitude * noise.perlin2(px, py) + waveCenter;
				const t = i / (segmentNum - 1);
				const color = getGradientColor(colors, t);

				if (i === 0) {
					offscreenCtx.moveTo(x, y);
				} else {
					offscreenCtx.strokeStyle = color;
					offscreenCtx.lineTo(x, y);
					// 今回は各セグメントごとに描画していますが、
					// 描画完了後に一括stroke()する方法も検討してください
					offscreenCtx.stroke();
					offscreenCtx.beginPath();
					offscreenCtx.moveTo(x, y);
				}
			});
		});

		// オフスクリーンキャンバスの内容をメインキャンバスに転送
		context.clearRect(0, 0, stageW, stageH);
		context.drawImage(offscreenCanvas, 0, 0);
	}

	/** グラデーション用の色計算関数（そのまま利用） */
	function getGradientColor(colors, t) {
		const colorCount = colors.length - 1;
		const index = Math.floor(t * colorCount);
		const startColor = colors[index];
		const endColor = colors[index + 1] || colors[index];
		const ratio = (t * colorCount) - index;
		return interpolateColor(startColor, endColor, ratio);
	}

	function interpolateColor(color1, color2, ratio) {
		const hex = (c) => parseInt(c, 16);
		const r1 = hex(color1.slice(1, 3)), g1 = hex(color1.slice(3, 5)), b1 = hex(color1.slice(5, 7));
		const r2 = hex(color2.slice(1, 3)), g2 = hex(color2.slice(3, 5)), b2 = hex(color2.slice(5, 7));
		const r = Math.round(r1 + (r2 - r1) * ratio);
		const g = Math.round(g1 + (g2 - g1) * ratio);
		const b = Math.round(b1 + (b2 - b1) * ratio);
		return `rgb(${r}, ${g}, ${b})`;
	}

}

